import { Box } from "@chakra-ui/react";
import React from "react";

interface ContainerProps {
  children: React.ReactNode;
}

function Container({ children }: ContainerProps) {
  return (
    <Box
      scrollBehavior="smooth"
      maxW="1280px"
      m="0 auto"
      px={["0.75rem", "1rem", "1.5rem", "2rem", "4rem"]}
    >
      {children}
    </Box>
  );
}

export default Container;
