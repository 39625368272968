/* eslint-disable import/no-unresolved */
import { Box, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";

interface DataProps {
  title: string;
  description: string;
  imgUrl: string;
}

function WhatCard({ title, description, imgUrl }: DataProps) {
  return (
    <Box
      borderWidth="1px"
      borderColor="#D4D4D4"
      p={["1.5rem 1rem", "1.5rem 1rem", "2rem 1.5rem"]}
    >
      <Image w="3rem" h="3rem" src={imgUrl} align={title} />
      <Heading
        my={["1.25rem", "1.25rem", "1.9rem"]}
        fontWeight="medium"
        fontSize={["1.25rem", "1.25rem", "1.5rem"]}
        color="black"
      >
        {title}
      </Heading>
      <Text
        fontWeight="light"
        fontSize={["0.875rem", "0.875rem", "1rem"]}
        color="black"
      >
        {description}
      </Text>
    </Box>
  );
}

export default WhatCard;
