import { Box, Flex, Image, Link } from "@chakra-ui/react";
import React from "react";
import { Logo } from "../assets/images";
import Container from "./Container";

function Footer() {
	return (
		<Box id="contact" bgColor="dark" py="2rem">
			<Container>
				<Flex alignItems="center" justifyContent="space-between">
					<Image w="82px" h="19px" src={Logo} alt="1mbp logo" />

					<Link color="white" href="mailto: hello@tmbp.ng">
						hello@tmbp.ng
					</Link>
				</Flex>
			</Container>
		</Box>
	);
}

export default Footer;
