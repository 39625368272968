/* eslint-disable import/no-unresolved */
import { Box, Flex, Heading, Image, SimpleGrid, Text } from "@chakra-ui/react";
import { Fenestra, Play, Unifi } from "assets/images";
import React from "react";

interface WorkProps {
	imgUrl: string;
	title: string;
	hint: string;
}

const works: WorkProps[] = [
	{
		imgUrl: Unifi,
		title: "UniFi all your financial services messages in one box",
		hint: "UniFi is a financial app that consolidate all your financial messages in a single box",
	},
	{
		imgUrl: Fenestra,
		title: "Providing for lenders to make smart decisions",
		hint: "Fenestra is a data API service that brings customer transactional data to lenders and others",
	},
];

function Playing() {
	return (
		<Box bgColor="white">
			<Box
				maxW="1440px"
				m="0 auto"
				py="6rem"
				px={["1rem", "2rem", "4rem", "0"]}
			>
				<Flex
					flexDir={["column", "column", "column", "row"]}
					alignItems={["flex-start", "flex-start", "flex-start", "center"]}
					gap="6rem"
				>
					<Box order={[2, 2, 2, 1]} w="full" pl={["0", "0", "0", "8.5rem"]}>
						<Heading
							fontSize={["2rem", "2rem", "2rem", "2rem", "2.5rem"]}
							fontWeight="medium"
							color="dark"
						>
							What we are playing with
						</Heading>

						<Text
							fontWeight="light"
							fontSize="1rem"
							color="dark"
							maxW="527px"
							mt="1.85rem"
							mb="3.5rem"
						>
							We experiment and play a lot. Sometimes we even break a few things
							😲. But in the end, with our deep scientific and engineering
							backgrounds, something good always come out.
						</Text>

						<SimpleGrid columns={[2, 2, 3, 2, 3]} spacing="1.5rem">
							{works.map(({ imgUrl, title, hint }) => (
								<Box key={title} maxW="180px">
									<Image src={imgUrl} w="full" h="auto" title={hint} />
									<Text
										fontSize="0.9rem"
										fontWeight="light"
										color="dark"
										mt="1.5rem"
									>
										{title}
									</Text>
								</Box>
							))}
						</SimpleGrid>
					</Box>
					<Image
						order={[1, 1, 1, 2]}
						w={["300px", "400px", "400px", "400px", "706px"]}
						h="auto"
						src={Play}
						alt="playing with"
					/>
				</Flex>
			</Box>
		</Box>
	);
}

export default Playing;
