import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";

interface HoverImageProps {
  imgUrl: string;
  title: string;
  hoverImgUrl: string;
}

function HoverImage({ imgUrl, title, hoverImgUrl }: HoverImageProps) {
  const [hover, setHover] = React.useState(false);
  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Image src={hover ? hoverImgUrl : imgUrl} w="full" h="auto" />
      <Text
        fontSize="0.9rem"
        fontWeight="light"
        color="white"
        mt="1.5rem"
        maxW="174px"
      >
        {title}
      </Text>
    </Box>
  );
}

export default HoverImage;
