import { Box, Highlight, Image, Text } from "@chakra-ui/react";
import React from "react";
import { AboutImg } from "../../assets/images";
import { Container } from "../../components";

function About() {
	return (
		<Box id="about">
			<Container>
				<Box py={["4rem", "4rem", "4rem", "8rem"]}>
					<Text
						color="dark"
						fontSize={["1.15rem", "1.5rem", "2rem", "3.125rem"]}
						fontWeight="light"
						textAlign="center"
						maxW="912px"
						mx="auto"
					>
						<Highlight
							query="financial technologies"
							styles={{ px: "2", py: "1", bg: "yellow" }}
						>
							TMBP is a venture studio that builds and experiments with cutting
							edge financial technologies within the African ecosystem.
						</Highlight>
					</Text>
				</Box>
			</Container>

			<Image src={AboutImg} alt="about 1mbp" w="100vw" h="auto" />
		</Box>
	);
}

export default About;
