/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./config/routes";
import { Footer, Header } from "./components";
import { theme } from "./config/theme";

export function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Header />
        <Routes />
        <Footer />
      </BrowserRouter>
    </ChakraProvider>
  );
}
