import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Hide,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React from "react";
import { Logo } from "../assets/images";
import Container from "./Container";

function Header() {
  return (
    <Box zIndex="1" pos="sticky" top="0" bgColor="dark" py="2rem">
      <Container>
        <Flex alignItems="center" justifyContent="space-between">
          <Image w="82px" h="19px" src={Logo} alt="1mbp logo" />

          <Flex
            display={["none", "none", "flex"]}
            alignItems="center"
            gap="2.5rem"
          >
            <Link color="white" href="/#about">
              About us
            </Link>
            <Link color="white" href="/#work">
              Our work
            </Link>
            <Link color="white" href="/#contact">
              Contact us
            </Link>
          </Flex>

          <Hide above="md">
            <Menu>
              <MenuButton color="white" fontSize="1.5rem" as={HamburgerIcon} />
              <MenuList>
                <MenuItem>
                  <Link color="dark" href="/#about">
                    About
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link color="dark" href="/#work">
                    Our work
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link color="dark" href="/#contact">
                    Contact us
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
          </Hide>
        </Flex>
      </Container>
    </Box>
  );
}

export default Header;
