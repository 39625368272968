/* eslint-disable import/prefer-default-export */
import { extendTheme } from "@chakra-ui/react";
// import { Button } from './components/Button';

export const theme = extendTheme({
  styles: {
    global: {
      button: {
        _focus: {
          outline: "none",
        },
      },
      "h1, h2, h3, h4, h5, h6": {
        fontWeight: "500",
      },
    },
  },
  fonts: {
    heading: "Sora, system-ui, sans-serif",
    body: "Sora, system-ui, sans-serif",
  },
  colors: {
    black: "#000",
    dark: "#020215",
    white: "#FFFFFF",
    teal: "#5EB1BF",
    yellow: "#EBEF25",
    grey: {
      50: "#D4D4D4",
    },
  },
  components: {
    // Button,
  },
});
