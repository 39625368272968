import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import { HeroImg } from "../../assets/images";
import { Container } from "../../components";

function Hero() {
	return (
		<Box bgColor="dark" py="5rem">
			<Container>
				<Flex
					flexDir={["column", "column", "column", "row"]}
					gap="2rem"
					alignItems="center"
					justifyContent="space-between"
				>
					<Box order={[2, 2, 2, 1, 1, 1]} maxW="460px">
						<Heading
							color="white"
							fontSize={["2rem", "2rem", "2.5rem", "5.625rem"]}
							lineHeight={["3rem", "3rem", "3rem", "6rem"]}
							fontWeight="medium"
							textAlign={["center", "center", "center", "left"]}
						>
							Creating the future today.
						</Heading>

						<Text
							mt={["1rem", "1rem", "1rem", "4rem"]}
							pl={["0", "0", "0", "2rem"]}
							pr={["0", "0", "0", "1rem"]}
							color="white"
							fontSize={["0.875rem", "0.875rem", "1rem"]}
							fontWeight="300"
							textAlign={["center", "center", "center", "left"]}
							borderLeftColor={[
								"transparent",
								"transparent",
								"transparent",
								"teal",
							]}
							borderLeftWidth={["0", "0", "0", "0.75rem"]}
						>
							The future doesn’t happen by itself. Ideas are found by curiosity.
							They’re given life with execution.
						</Text>
					</Box>
					<Image
						order={[1, 1, 1, 2, 2, 2]}
						w={["220px", "220px", "220px", "516px"]}
						h={["254px", "254px", "254px", "550px"]}
						src={HeroImg}
						alt="hero"
					/>
				</Flex>
			</Container>
		</Box>
	);
}

export default Hero;
