/* eslint-disable import/no-unresolved */
import { Box, Flex, Heading, Image, SimpleGrid, Text } from "@chakra-ui/react";
import {
	Blockacash,
	BlockacashColor,
	Built,
	Fidelity,
	FidelityColor,
	Irorun,
	IrorunColor,
	Lendsqr,
	LendsqrColor,
	RoundLogo,
	TrustBanc,
	TrustBancColor,
} from "assets/images";
import React from "react";
import HoverImage from "./HoverImage";

interface WorkProps {
	imgUrl: string;
	hoverImgUrl: string;
	title: string;
}

const works: WorkProps[] = [
	{
		imgUrl: Lendsqr,
		hoverImgUrl: LendsqrColor,
		title: "Lending-as-a-Service helping lenders launch and scale in minutes",
	},
	{
		imgUrl: Fidelity,
		hoverImgUrl: FidelityColor,
		title: "Optimizing digital banking and analytics for staff on the go",
	},
	{
		imgUrl: TrustBanc,
		hoverImgUrl: TrustBancColor,
		title: "Digital Banking for digital nomads",
	},
	{
		imgUrl: Irorun,
		hoverImgUrl: IrorunColor,
		title: "Digital lending service",
	},
	{
		imgUrl: Blockacash,
		hoverImgUrl: BlockacashColor,
		title: "Digital lending service",
	},
];

function Work() {
	return (
		<Box id="work" bgColor="dark">
			<Box
				maxW="1440px"
				m="0 auto"
				py="6rem"
				px={["1rem", "2rem", "4rem", "0"]}
			>
				<Flex
					flexDir={["column", "column", "column", "row"]}
					alignItems={["flex-start", "flex-start", "flex-start", "center"]}
					gap="6.25rem"
				>
					<Image w="438px" h="auto" src={Built} />
					<Box pos="relative" pr={["0", "0", "0", "8.5rem"]}>
						<Image
							pos="absolute"
							top={["-5rem", "-5rem", "-1rem", "0"]}
							right={["-1rem", "-1rem", "-8rem", "2rem", "8.5rem"]}
							src={RoundLogo}
							alt="round logo"
							w={["6rem", "6rem", "6rem", "6rem", "8.75rem"]}
							h={["6rem", "6rem", "6rem", "6rem", "8.75rem"]}
						/>
						<Box>
							<Heading fontSize="2.5rem" fontWeight="medium" color="white">
								What we have built
							</Heading>

							<Text
								fontWeight="light"
								fontSize="1rem"
								color="white"
								maxW="527px"
								mt="1.85rem"
								mb="3.5rem"
							>
								Our approach to use technologies to make business better has
								always been the same. We identify high impact sectors, such as
								fintechs, and look to build scalable platforms that satisfy a
								real customer need.
							</Text>
						</Box>
						<SimpleGrid columns={[2, 2, 2, 2, 3]} spacing={[6, 6, 10]}>
							{works.map(({ imgUrl, title, hoverImgUrl }) => (
								<HoverImage
									key={title}
									title={title}
									imgUrl={imgUrl}
									hoverImgUrl={hoverImgUrl}
								/>
							))}
						</SimpleGrid>
					</Box>
				</Flex>
			</Box>
		</Box>
	);
}

export default Work;
