/* eslint-disable import/no-unresolved */
import { Box, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import { Investing, Ideation, Experimenting, Scaling } from "assets/images";
import { Container } from "../../components";
import WhatCard from "./WhatCard";

interface DataProps {
	title: string;
	description: string;
	imgUrl: string;
}

const data: DataProps[] = [
	{
		imgUrl: Ideation,
		title: "Ideating",
		description:
			"We play with all ideas; none is too stupid. The best things're not apparent to most people",
	},
	{
		imgUrl: Experimenting,
		title: "Experimenting",
		description:
			"We're constantly hacking and creating MVPs. Breaking things and patching them up. Stretching envelopes",
	},
	{
		imgUrl: Investing,
		title: "Investing",
		description:
			"We're not scared to put money and resources behind ideas that could scale. We nurture and incubate",
	},
	{
		imgUrl: Scaling,
		title: "Scaling",
		description:
			"Scale is life for successful ideas and this is where we excel. We push ideas to scale",
	},
];

function WhatWeDo() {
	return (
		<Box py="5.625rem">
			<Container>
				<Heading
					fontWeight="medium"
					fontSize={["2rem", "2rem", "2.5rem"]}
					color="black"
				>
					What we do
				</Heading>
				<Text
					py="1.2rem"
					fontWeight="light"
					maxW="927px"
					fontSize={["0.875rem", "0.875rem", "1rem"]}
					lineHeight="tall"
				>
					As a venture studio, we specialize in developing and bringing
					innovative ideas to life by incubating and scaling them into
					successful ventures, often in collaboration with other founders. Our
					goal is to quickly launch and create value.
				</Text>

				<SimpleGrid
					mt="2rem"
					spacing={["1rem", "1rem", "1.5rem"]}
					columns={[1, 2, 2, 4]}
				>
					{data.map(({ title, description, imgUrl }) => (
						<WhatCard
							key={title}
							title={title}
							description={description}
							imgUrl={imgUrl}
						/>
					))}
				</SimpleGrid>
			</Container>
		</Box>
	);
}

export default WhatWeDo;
