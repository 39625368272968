import React from "react";
import { About, Hero, Playing, WhatWeDo, Work } from "../feature/home";

function Home() {
  return (
    <>
      <Hero />
      <About />
      <WhatWeDo />
      <Work />
      <Playing />
    </>
  );
}

export default Home;
